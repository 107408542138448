import { CreateSheet } from 'aphrodite';

export const fadeAnimation = (AnimationTime: number) => CreateSheet({
    base: {
        ":nth-child(1n) .dropdown-fade-enter": {
            opacity: 0,
            transform: "translateY(20px)",
        },
        ":nth-child(1n) .dropdown-fade-enter-active": {
            opacity: 1,
            transform: "translateY(0)",
            transition: `opacity ${AnimationTime}ms ease-out, transform ${AnimationTime}ms ease-out`,
        },
        ":nth-child(1n) .dropdown-fade-enter-done": {
            opacity: 1,
            transform: "translateY(0)",
            backfaceVisibility: "hidden",
        },
        ":nth-child(1n) .dropdown-fade-exit": {
            opacity: 1,
            transform: "translateY(0)",
        },
        ":nth-child(1n) .dropdown-fade-exit-active": {
            opacity: 0,
            transform: "translateY(20px)",
            transition: [
                `opacity ${AnimationTime}ms ease-in`, 
                `transform ${AnimationTime}ms ease-in`,
            ].join(",")
        },
    },
}).base
