import {useState, useEffect, useRef} from 'react';
import i18next from 'i18next';
import {GetPath, history} from 'packages/history/history';
import useMaxWidth from 'packages/hooks/useMaxWidth';
import {useLocalization} from 'packages/localization/localization';
import {languages} from 'packages/localization/languages';
import {changeLanguage} from 'packages/localization/localization';
import {OpenPage} from 'packages/history/history';
import {useLocation} from 'react-router-dom';
// components
import Logo from '../logo/logo.react';
import Dropdown from 'packages/dropdowns/dropdownBase.react';
// icons
import {ReactComponent as Menu} from 'src/icons/menu.svg';
import {ReactComponent as CrossIcon} from 'src/icons/cross.svg';
import {ReactComponent as LanguageIcon} from 'src/icons/language.svg';
import {ReactComponent as ArrowIcon} from 'src/icons/arrow-forward.svg';
import {ReactComponent as FacebookIcon} from 'src/icons/facebook.svg';
import {ReactComponent as InstagramIcon} from 'src/icons/instagram.svg';
import {ReactComponent as YoutubeIcon} from 'src/icons/youtube.svg';
// styles
import {css, CreateSheet} from 'aphrodite';
import {Sizes} from 'src/themes/layouts';
import {
    Accent1,
    ElementBg,
    LightGray,
    PageBg,
    Primary,
    Secondary,
    StrokeColor,
    TextArea,
    TextColor,
    WhiteText,
} from 'src/themes/colors';
import {MakeButton, PrimaryFilled, WhiteFilled} from 'src/themes/button';

export default function Header({active, transparent}: {active?: string; transparent?: boolean}) {
    const {t, tReady} = useLocalization('header');
    const [mobileMenu, setMobileMenu] = useState<boolean>(false);
    const [hovered, setHovered] = useState<boolean>(false);

    const openMobileMenu = () => {
        setMobileMenu(true);
    };

    const closeMobileMenu = () => {
        setMobileMenu(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            const windowElm = window as any;
            const scrollPos =
                windowElm?.scrollY || windowElm.scrollTop || document.getElementsByTagName('html')[0].scrollTop;
            if (scrollPos === 0 && hovered) setHovered(false);
            if (scrollPos !== 0 && !hovered) setHovered(true);
        };

        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [hovered]);

    if (!tReady) {
        return null;
    }

    return (
        <div className={css(Styles.header, transparent && !hovered && Styles.transparent)}>
            <div className={css(Styles.container)}>
                <LogoHeader white={transparent && !hovered} />
                <div className={css(Styles.col, Styles.headerNav, i18next.language === 'ru' && Styles.headerNavRu)}>
                    <MenuList
                        active={active}
                        transparent={transparent}
                        hovered={hovered}
                    />
                </div>
                <div className={css(Styles.col, Styles.right)}>
                    <a
                        {...{
                            href: GetPath('#booking'),
                            className: css(
                                MakeButton(transparent && !hovered ? WhiteFilled : PrimaryFilled),
                                Styles.booking
                            ),
                            onClick: OpenPage('#booking'),
                        }}
                    >
                        {t('links.rent')}
                    </a>
                    <div className={css(Styles.language)}>
                        <LanguagesMenu
                            transparent={transparent}
                            hovered={hovered}
                        />
                    </div>
                    <div
                        className={css(
                            Styles.mobileMenuToggle,
                            i18next.language === 'ru' && Styles.mobileMenuToggleRu,
                            transparent && !hovered && Styles.mobileMenuToggleTransparent
                        )}
                        onClick={openMobileMenu}
                    >
                        <Menu
                            className={css(
                                Styles.mobileMenuIcon,
                                transparent && !hovered && Styles.mobileMenuIconTransparent
                            )}
                            width="22"
                            height="22"
                        />
                    </div>
                    <MenuMobile
                        visible={mobileMenu}
                        close={closeMobileMenu}
                        active={active}
                    />
                </div>
            </div>
        </div>
    );
}

const LogoHeader = ({white}: {white?: boolean}) => {
    const isSmallScreen = useMaxWidth(700);

    return (
        <div className={css(Styles.col, Styles.left)}>
            <Logo
                white={white && !isSmallScreen}
                short={isSmallScreen}
            />
        </div>
    );
};

interface menuProps {
    visible: boolean;
    active?: string;
    close: () => void;
}

const MenuMobile = (props: menuProps) => {
    const {t, tReady} = useLocalization('header');
    const {visible, close} = props;

    const wrapper = useRef<HTMLDivElement>(null);

    const open = (link: string) => (e: React.MouseEvent) => {
        e.preventDefault();
        close();
        setTimeout(() => {
            history.push(link);
        }, 200);
    };

    useEffect(() => {
        function onClickOverlay(e: MouseEvent) {
            if (visible) {
                if (wrapper.current && e.target instanceof HTMLElement) {
                    if (!wrapper.current.contains(e.target)) {
                        close();
                    }
                }
            }
        }

        window.addEventListener('click', onClickOverlay);
        return () => {
            window.removeEventListener('click', onClickOverlay);
        };
    }, [visible, close]);

    if (!tReady) {
        return null;
    }

    return (
        <div
            ref={wrapper}
            className={css(
                Styles.mobileMenu,
                visible && Styles.mobileMenuVisible,
                visible && i18next.language === 'ru' && Styles.mobileMenuVisibleRu
            )}
        >
            {visible && (
                <>
                    <div
                        className={css(Styles.menuClose)}
                        onClick={close}
                    >
                        <CrossIcon
                            width="20"
                            height="20"
                            className={css(Styles.menuCloseIcon)}
                        />
                    </div>
                    <div className={css(Styles.menuTop)}>
                        <h4 className={css(Styles.mobileMenuItem)}>{t('menu')}</h4>
                        <LanguagesMenu isMobile />
                    </div>
                    <MenuList
                        close={close}
                        isMobile
                    />
                    <a
                        {...{
                            href: GetPath('#booking'),
                            className: css(MakeButton(PrimaryFilled), Styles.mobileMenuBtn, Styles.booking),
                            onClick: open(GetPath('#booking')),
                        }}
                    >
                        {t('links.rent')}
                    </a>
                    <div className={css(Styles.contact)}>
                        <p className={css(Styles.contactText)}>
                            <a
                                href="tel:+393429287530"
                                className={css(Styles.phone)}
                            >
                                +39 342 928 75 30
                            </a>
                        </p>
                        <p className={css(Styles.contactText)}>
                            <a
                                href="tel:hello@motoragazzi.com"
                                className={css(Styles.contactLink)}
                            >
                                hello@motoragazzi.com
                            </a>
                        </p>
                        <ul className={css(Styles.social)}>
                            <li className={css(Styles.socialItem)}>
                                <a
                                    {...{
                                        className: css(Styles.socialItemLink),
                                        href: 'https://www.youtube.com/channel/UCjNrhJZQP931J4e8SxapTyw',
                                        target: '_blank',
                                        rel: 'noopener noreferrer',
                                        'aria-label': 'Youtube',
                                    }}
                                >
                                    <YoutubeIcon
                                        className={css(Styles.socialItemIcon)}
                                        width="30"
                                        height="30"
                                    />
                                </a>
                            </li>
                            <li className={css(Styles.socialItem)}>
                                <a
                                    {...{
                                        className: css(Styles.socialItemLink),
                                        href: 'https://www.instagram.com/motoragazzi/',
                                        target: '_blank',
                                        rel: 'noopener noreferrer',
                                        'aria-label': '@motoragazzi',
                                    }}
                                >
                                    <InstagramIcon
                                        className={css(Styles.socialItemIcon)}
                                        width="30"
                                        height="30"
                                    />
                                </a>
                            </li>
                            <li className={css(Styles.socialItem)}>
                                <a
                                    {...{
                                        className: css(Styles.socialItemLink),
                                        href: 'https://www.facebook.com/motoragazzi',
                                        target: '_blank',
                                        rel: 'noopener noreferrer',
                                        'aria-label': 'Facebook',
                                    }}
                                >
                                    <FacebookIcon
                                        className={css(Styles.socialItemIcon)}
                                        width="30"
                                        height="30"
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
};

const LanguagesMenu = ({
    isMobile,
    transparent,
    hovered,
}: {
    isMobile?: boolean;
    transparent?: boolean;
    hovered?: boolean;
}) => {
    const location = useLocation();

    const change = (code: string) => {
        const [, , ...restPathParts] = location.pathname.split('/');

        const newPath = `/${code}/${restPathParts.join('/')}`;

        history.push(newPath);
        changeLanguage(code);
    };

    return (
        <Dropdown
            {...{
                animation: 'fade',
                header: () => (
                    <div
                        className={css(
                            Styles.languageItem,
                            !hovered && transparent && Styles.languageItemTransparent,
                            isMobile && Styles.languageMobile
                        )}
                    >
                        <LanguageIcon
                            className={css(
                                Styles.languageIcon,
                                !hovered && transparent && Styles.languageIconTransparent
                            )}
                            width="24"
                            height="24"
                        />
                        <span className={css(Styles.lngTitle)}>{i18next.language}</span>
                    </div>
                ),
                dropElement: () => (
                    <ul className={css(Styles.dropdownActions)}>
                        {languages.map(lan => {
                            if (!lan.enabled || !lan.inMenu) return null;
                            return (
                                <li
                                    {...{
                                        key: lan.code,
                                        className: css(Styles.dropdownAction),
                                        onClick: () => change(lan.code),
                                    }}
                                >
                                    {lan.name}
                                </li>
                            );
                        })}
                    </ul>
                ),
            }}
        />
    );
};

const MenuList = ({
    active,
    isMobile,
    transparent,
    hovered,
    close,
}: {
    active?: string;
    isMobile?: boolean;
    transparent?: boolean;
    hovered?: boolean;
    close?: () => void;
}) => {
    const {t, tReady} = useLocalization('header');

    const open = (link: string) => (e: React.MouseEvent) => {
        e.preventDefault();
        if (isMobile && close) {
            close();
            setTimeout(() => {
                history.push(link);
            }, 200);
        } else {
            history.push(link);
        }
    };

    if (!tReady) {
        return null;
    }

    return (
        <ul className={css(Styles.nav, isMobile && Styles.navMobile)}>
            <li className={css(Styles.navItem, isMobile && Styles.navItemMobile)}>
                <a
                    href={GetPath('')}
                    className={css(
                        Styles.navItemLink,
                        isMobile && Styles.navItemLinkMobile,
                        active === 'home' && Styles.navItemLinkActive,
                        transparent && !hovered && Styles.navItemLinkTransparent
                    )}
                    onClick={open(GetPath(''))}
                >
                    <span>{t('links.home')}</span>
                    <ArrowIcon
                        className={css(Styles.navItemArrow, isMobile && Styles.navItemMobileArrow)}
                        width="18"
                        height="18"
                    />
                </a>
            </li>
            <li className={css(Styles.navItem, isMobile && Styles.navItemMobile)}>
                <a
                    href={GetPath('my-reservations')}
                    className={css(
                        Styles.navItemLink,
                        isMobile && Styles.navItemLinkMobile,
                        active === 'my-reservations' && Styles.navItemLinkActive,
                        transparent && !hovered && Styles.navItemLinkTransparent
                    )}
                    onClick={open(GetPath('my-reservations'))}
                >
                    <span>{t('links.reservations')}</span>
                    <ArrowIcon
                        className={css(Styles.navItemArrow, isMobile && Styles.navItemMobileArrow)}
                        width="18"
                        height="18"
                    />
                </a>
            </li>
            <li className={css(Styles.navItem, isMobile && Styles.navItemMobile)}>
                <a
                    href={GetPath('fleet')}
                    className={css(
                        Styles.navItemLink,
                        isMobile && Styles.navItemLinkMobile,
                        active === 'fleet' && Styles.navItemLinkActive,
                        transparent && !hovered && Styles.navItemLinkTransparent
                    )}
                    onClick={open(GetPath('fleet'))}
                >
                    <span>{t('links.fleet')}</span>
                    <ArrowIcon
                        className={css(Styles.navItemArrow, isMobile && Styles.navItemMobileArrow)}
                        width="18"
                        height="18"
                    />
                </a>
            </li>
            <li className={css(Styles.navItem, isMobile && Styles.navItemMobile)}>
                <a
                    href={GetPath('one-day-tours')}
                    className={css(
                        Styles.navItemLink,
                        isMobile && Styles.navItemLinkMobile,
                        active === 'oneTours' && Styles.navItemLinkActive,
                        transparent && !hovered && Styles.navItemLinkTransparent
                    )}
                    onClick={open(GetPath('one-day-tours'))}
                >
                    <span>{t('links.oneTours')}</span>
                    <ArrowIcon
                        className={css(Styles.navItemArrow, isMobile && Styles.navItemMobileArrow)}
                        width="18"
                        height="18"
                    />
                </a>
            </li>
            {/*
            <li className={css(Styles.navItem, isMobile && Styles.navItemMobile)}>
                <a
                    href="/multi-day-tour"
                    className={css(
                        Styles.navItemLink,
                        isMobile && Styles.navItemLinkMobile,
                        active === 'multiTours' && Styles.navItemLinkActive,
                        transparent && !hovered && Styles.navItemLinkTransparent
                    )}
                    onClick={open('multi-day-tour')}
                >
                    <span>{t('links.multiTours')}</span>
                    <ArrowIcon
                        className={css(Styles.navItemArrow, isMobile && Styles.navItemMobileArrow)}
                        width="18"
                        height="18"
                    />
                </a>
            </li>
            */}
            <li className={css(Styles.navItem, isMobile && Styles.navItemMobile)}>
                <a
                    href={GetPath('franchising')}
                    className={css(
                        Styles.navItemLink,
                        isMobile && Styles.navItemLinkMobile,
                        active === 'franchising' && Styles.navItemLinkActive,
                        transparent && !hovered && Styles.navItemLinkTransparent
                    )}
                    onClick={open(GetPath('franchising'))}
                >
                    <span>{t('links.franchising')}</span>
                    <ArrowIcon
                        className={css(Styles.navItemArrow, isMobile && Styles.navItemMobileArrow)}
                        width="18"
                        height="18"
                    />
                </a>
            </li>
            <li className={css(Styles.navItem, isMobile && Styles.navItemMobile)}>
                <a
                    href={GetPath('faq')}
                    className={css(
                        Styles.navItemLink,
                        isMobile && Styles.navItemLinkMobile,
                        active === 'faq' && Styles.navItemLinkActive,
                        transparent && !hovered && Styles.navItemLinkTransparent
                    )}
                    onClick={open(GetPath('faq'))}
                >
                    <span>{t('links.faq')}</span>
                    <ArrowIcon
                        className={css(Styles.navItemArrow, isMobile && Styles.navItemMobileArrow)}
                        width="18"
                        height="18"
                    />
                </a>
            </li>
            <li className={css(Styles.navItem, isMobile && Styles.navItemMobile)}>
                <a
                    href={GetPath('contacts')}
                    className={css(
                        Styles.navItemLink,
                        isMobile && Styles.navItemLinkMobile,
                        isMobile && Styles.navItemLinkMobileLast,
                        active === 'contacts' && Styles.navItemLinkActive,
                        transparent && !hovered && Styles.navItemLinkTransparent
                    )}
                    onClick={open(GetPath('contacts'))}
                >
                    <span>{t('links.contacts')}</span>
                    <ArrowIcon
                        className={css(Styles.navItemArrow, isMobile && Styles.navItemMobileArrow)}
                        width="18"
                        height="18"
                    />
                </a>
            </li>
        </ul>
    );
};

const Styles = CreateSheet({
    header: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        display: 'flex',
        height: Sizes.header,
        alignItems: 'center',
        backgroundColor: PageBg,
        transition: 'background-color 0.25s ease-in 0s, box-shadow 0.25s ease-in 0s',
        zIndex: 20,
    },
    transparent: {
        background: 'transparent',
    },
    container: {
        display: 'flex',
        width: '100%',
        margin: '0 auto',
        padding: '0 50px',
        '@media(max-width: 900px)': {
            padding: '0 20px',
        },
    },
    col: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    left: {
        justifyContent: 'flex-start',
    },
    right: {
        justifyContent: 'flex-end',
    },
    nav: {
        display: 'flex',
        margin: 0,
        padding: 0,
        alignItems: 'center',
    },
    navItem: {
        margin: '0 15px',
        listStyle: 'none',
        '@media(max-width: 1400px)': {
            margin: '0 10px',
        },
    },
    navItemLink: {
        position: 'relative',
        color: Secondary,
        textDecoration: 'none',
        fontSize: 16,
        fontWeight: 600,
        whiteSpace: 'nowrap',
        listStyle: 'none',
        userSelect: 'none',
        ':after': {
            content: '" "',
            position: 'absolute',
            bottom: -3,
            left: 0,
            width: 0,
            height: 2,
            background: Accent1,
            transition: 'all 0.5s cubic-bezier(.645,.045,.355,1)',
        },
        ':hover': {
            color: TextColor,
            ':after': {
                width: '100%',
                opacity: 1,
            },
        },
    },
    navItemLinkTransparent: {
        color: WhiteText,
        ':after': {
            background: '#fff',
        },
        ':hover': {
            color: WhiteText,
        },
    },
    navItemLinkActive: {
        color: TextColor,
        ':after': {
            width: '100%',
            opacity: 1,
        },
    },
    languageItem: {
        display: 'flex',
        marginLeft: 40,
        fontSize: 16,
        fontWeight: 600,
        color: TextColor,
        alignItems: 'center',
        cursor: 'pointer',
        ':hover': {
            color: Secondary,
            ':nth-child(1n) svg': {
                fill: Secondary,
            },
        },
    },
    languageItemTransparent: {
        color: WhiteText,
        ':hover': {
            color: WhiteText,
            ':nth-child(1n) svg': {
                fill: WhiteText,
            },
        },
    },
    languageIcon: {
        display: 'block',
        marginRight: 15,
        fill: TextColor,
    },
    languageIconTransparent: {
        fill: WhiteText,
    },
    dropdownActions: {
        position: 'absolute',
        top: 'calc(100% + 5px)',
        right: 0,
        minWidth: '100%',
        margin: 0,
        padding: 7,
        fontSize: '1rem',
        fontWeight: 600,
        listStyle: 'none',
        color: TextColor,
        background: ElementBg,
        borderRadius: 10,
        boxShadow: '0px 10px 20px rgba(22, 22, 22, 0.05)',
        border: `1px solid ${StrokeColor}`,
        userSelect: 'none',
        zIndex: 1,
    },
    dropdownAction: {
        padding: '5px 10px',
        whiteSpace: 'nowrap',
        borderRadius: 5,
        ':hover': {
            background: TextArea,
        },
    },
    mobileMenuToggle: {
        display: 'none',
        marginLeft: 40,
        width: 44,
        height: 44,
        alignItems: 'center',
        justifyContent: 'center',
        background: TextArea,
        borderRadius: '50%',
        cursor: 'pointer',
        '@media(max-width: 1270px)': {
            display: 'flex',
        },
    },
    mobileMenuToggleRu: {
        '@media(max-width: 1670px)': {
            display: 'flex',
        },
    },
    mobileMenuToggleTransparent: {
        background: 'transparent',
    },
    mobileMenuIcon: {
        display: 'block',
        fill: TextColor,
    },
    mobileMenuIconTransparent: {
        fill: WhiteText,
    },
    mobileMenu: {
        position: 'fixed',
        top: 10,
        right: -375,
        bottom: 10,
        width: 375,
        padding: '20px 35px 35px 35px',
        backgroundColor: ElementBg,
        boxShadow: '0px 10px 20px rgba(22, 22, 22, 0.05)',
        borderRadius: 20,
        border: `1px solid ${LightGray}`,
        transform: 'translate(0, 0px)',
        zIndex: 1297,
        overflow: 'auto',
        transition: 'transform 0.2s ease-in-out',
        '@media(max-width: 500px)': {
            top: 0,
            right: '-100%',
            bottom: 0,
            width: 500,
            maxWidth: '100%',
            borderRadius: 0,
        },
    },
    mobileMenuVisible: {
        transform: 'translate(0, 0px)',
        '@media(max-width: 1270px)': {
            transform: 'translate(-385px)',
        },
        '@media(max-width: 500px)': {
            transform: 'translate(-100%)',
        },
    },
    mobileMenuVisibleRu: {
        '@media(max-width: 1670px)': {
            transform: 'translate(-385px)',
        },
    },
    mobileMenuItem: {
        margin: 0,
        fontSize: 20,
        fontWeight: 600,
        color: LightGray,
    },
    mobileMenuBtn: {
        display: 'block',
        width: '100%',
        marginTop: 20,
    },
    menuTop: {
        display: 'flex',
        margin: '0 50px 25px 0',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    menuClose: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 20,
        right: 35,
        width: 30,
        height: 30,
        borderRadius: 3,
        cursor: 'pointer',
        ':hover': {
            backgroundColor: TextArea,
        },
    },
    menuCloseIcon: {
        display: 'block',
        fill: TextColor,
    },
    navMobile: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    navItemMobile: {
        width: '100%',
        margin: 0,
        '@media(max-width: 1400px)': {
            margin: 0,
        },
    },
    navItemArrow: {
        display: 'none',
        fill: Accent1,
    },
    navItemMobileArrow: {
        display: 'block',
    },
    navItemLinkMobile: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        margin: 0,
        padding: '20px 0',
        color: TextColor,
        justifyContent: 'space-between',
        borderTop: `1px solid ${LightGray}`,
        '::after': {
            display: 'none',
        },
    },
    navItemLinkMobileLast: {
        borderBottom: `1px solid ${LightGray}`,
    },
    languageMobile: {
        margin: '0 0 0 30px',
    },
    contactText: {
        margin: '0 0 15px 0',
    },
    contact: {
        marginTop: 25,
        padding: 30,
        fontSize: 18,
        fontWeight: 600,
        color: TextColor,
        textAlign: 'center',
        background: TextArea,
        borderRadius: 10,
    },
    phone: {
        fontSize: 22,
        color: TextColor,
        textDecoration: 'none',
    },
    contactLink: {
        color: TextColor,
        textDecoration: 'none',
    },
    social: {
        display: 'flex',
        margin: '25px 0 0 0',
        padding: 0,
        alignItems: 'center',
        justifyContent: 'center',
        listStyle: 'none',
    },
    socialItem: {
        margin: '0 20px',
    },
    socialItemLink: {
        display: 'block',
    },
    socialItemIcon: {
        display: 'block',
        fill: Primary,
    },
    language: {
        '@media(max-width: 600px)': {
            display: 'none',
        },
    },
    lngTitle: {
        textTransform: 'uppercase',
        transition: 'color 0.25s ease-in-out',
    },
    headerNav: {
        '@media(max-width: 1270px)': {
            display: 'none',
        },
    },
    headerNavRu: {
        '@media(max-width: 1670px)': {
            display: 'none',
        },
    },
    booking: {
        whiteSpace: 'nowrap',
    },
});
