import Renderer from 'packages/lightbox/ModalRoot.react';
import {
    listenOnSessionChanges,
    listenOnSignIn,
    listenOnSignOut,
    LoadSession,
    unlistenOnSessionChanges,
    unlistenOnSignIn,
    unlistenOnSignOut,
} from 'packages/session/session';
import {Fragment, useEffect, useState} from 'react';
import ReactTooltip from 'react-tooltip';
import RouterApp from './router.react';
import useOnce from 'packages/hooks/useOnce';

export default function App() {
    const [Loading, SetLoading] = useState(true);
    const [Reload, SetReload] = useState(false);

    useOnce(() => {
        async function Init() {
            try {
                await LoadSession();
                SetLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        Init();
    });

    function onSessionChange() {
        SetReload(!Reload);
    }

    useEffect(() => {
        listenOnSessionChanges(onSessionChange);
        listenOnSignIn(onSessionChange);
        listenOnSignOut(onSessionChange);

        return () => {
            unlistenOnSessionChanges(onSessionChange);
            unlistenOnSignIn(onSessionChange);
            unlistenOnSignOut(onSessionChange);
        };
    });

    if (Loading) {
        return null;
    }

    return (
        <Fragment>
            <Renderer />
            <RouterApp />
            <ReactTooltip />
        </Fragment>
    );
}
