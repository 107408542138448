import CheckResultRuntime from 'packages/helpers/CheckResultRuntime';
import {ResultWithError} from 'packages/errors/errors';
import {put} from 'packages/rest/api';
import {SessionRaw, SessionRuntime} from '../model';

export type SignInRequest = {
    identifier: string;
    password: string;
};

export async function SignInAPI(body: SignInRequest): Promise<ResultWithError<SessionRaw>> {
    const res = await put({url: 'session', body});

    return CheckResultRuntime(SessionRuntime, res);
}
