import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import path from 'path';
import {CreateError, ResultWithError} from '../errors/errors';
import {Request, RequestBase, Response} from './model';
import {Runtype} from 'runtypes';
import CheckResultRuntime from 'packages/helpers/CheckResultRuntime';

export async function get<R = never, E = never>(
    cfg: RequestBase,
    runtype?: Runtype<R>
): Promise<ResultWithError<R, E>> {
    const res = await MakeCall<R, E>({
        ...cfg,
        method: 'GET',
    });

    return runtype ? CheckResultRuntime(runtype, res) : res;
}

export async function post<R = never, E = never>(
    cfg: RequestBase,
    runtype?: Runtype<R>
): Promise<ResultWithError<R, E>> {
    const res = await MakeCall<R, E>({
        ...cfg,
        method: 'POST',
    });

    return runtype ? CheckResultRuntime(runtype, res) : res;
}

export async function put<R = never, E = never>(
    cfg: RequestBase,
    runtype?: Runtype<R>
): Promise<ResultWithError<R, E>> {
    const res = await MakeCall<R, E>({
        ...cfg,
        method: 'PUT',
    });

    return runtype ? CheckResultRuntime(runtype, res) : res;
}

export async function patch<R = never, E = never>(
    cfg: RequestBase,
    runtype?: Runtype<R>
): Promise<ResultWithError<R, E>> {
    const res = await MakeCall<R, E>({
        ...cfg,
        method: 'PATCH',
    });

    return runtype ? CheckResultRuntime(runtype, res) : res;
}

export async function del<R = never, E = never>(
    cfg: RequestBase,
    runtype?: Runtype<R>
): Promise<ResultWithError<R, E>> {
    const res = await MakeCall<R, E>({
        ...cfg,
        method: 'DELETE',
    });

    return runtype ? CheckResultRuntime(runtype, res) : res;
}

async function MakeCall<R = never, E = never>(cfg: Request): Promise<ResultWithError<R, E>> {
    const url = cfg.remote ? cfg.url : `${document.location.origin}${path.join('/api/v1/', cfg.url)}`;
    let headers: Record<string, any> = {};

    if (cfg.headers) {
        headers = {...headers, ...cfg.headers};
    }

    if (!cfg.query) {
        cfg.query = {};
    }

    const config: AxiosRequestConfig = {
        method: cfg.method,
        url,
        headers,
        params: cfg.query,
        data: cfg.body,
        validateStatus: status => status >= 200 && status < 300,
    };

    try {
        const res = await axios.request<Response<R>>(config);
        return CreateResponse<R, E>(res);
    } catch (error: any) {
        if (typeof error.response != 'undefined') {
            return [
                null,
                CreateError<E>(
                    error.response.data.status_code,
                    error.response.data.status_text,
                    error.response.data.payload,
                    error.response.status
                ),
            ];
        } else {
            return [null, CreateError<E>(error)];
        }
    }
}

function CreateResponse<R, E>(res: AxiosResponse<Response<R>>): ResultWithError<R, E> {
    if (
        typeof res.headers['content-type'] != 'string' ||
        res.headers['content-type'].toLowerCase() !== 'application/json'
    ) {
        return [null, CreateError<E>('response is not json')];
    }

    const data: R = (res.data.payload ? res.data.payload : res.data) as R;
    return [data, null];
}

export type GetListParams = {
    order_by?: string;
    order_dir?: 'ASC' | 'DESC';
    page_id?: number;
    page_size?: number;
    q?: string;
    from?: number;
    to?: number;
};
