import CheckResultRuntime from 'packages/helpers/CheckResultRuntime';
import {ResultWithError} from 'packages/errors/errors';
import {post} from 'packages/rest/api';
import {SessionRaw, SessionRuntime} from '../model';

export async function VerifyRequestedPhoneAPI(
    code: string,
    phone?: string
): Promise<ResultWithError<SessionRaw>> {
    const res = await post({url: 'session/phones/verifications', body: {code, phone}});
    return CheckResultRuntime(SessionRuntime, res);
}
