import Button, {ButtonTheme, ButtonLayout} from 'packages/buttons/button';
import LoadButtonReact from 'packages/buttons/LoadButton.react';
import {Accent2, Accent1, WhiteText, TextColor} from './colors';

export const DefaultLayout: ButtonLayout = {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: 16,
    borderRadius: 40,
    padding: '11px 30px',
    cursor: 'pointer',
    textAlign: 'center',
    transition: 'box-shadow .25s ease-in, filter .25s ease-in, width .25s ease-in',
    fontWeight: 600,
    border: 0,
};

export const PrimaryFilled: ButtonTheme = {
    plain: {
        background: Accent1,
        color: WhiteText,
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: '#4DB047',
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const SecondaryFilled: ButtonTheme = {
    plain: {
        background: Accent2,
        color: WhiteText,
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: '#F87160',
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export const WhiteFilled: ButtonTheme = {
    plain: {
        background: WhiteText,
        color: TextColor,
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
};

export function IsStyleTheme(pet: any | ButtonTheme): pet is ButtonTheme {
    return (pet as ButtonTheme).plain !== undefined;
}

export function MakeButton(theme: ButtonTheme, layout?: ButtonLayout) {
    return Button({...DefaultLayout, ...layout}, theme);
}

type props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    loading: boolean;
    theme: ButtonTheme;
    layout?: ButtonLayout;
};

export function LoadButton(props: props) {
    return (
        <LoadButtonReact
            {...{
                ...props,
                layout: {...DefaultLayout, ...props.layout},
            }}
        />
    );
}
