import {createBrowserHistory, Location} from 'history';
import React from 'react';
import GA4React from 'ga-4-react';
import i18next from 'i18next';
import {generatePath} from 'react-router-dom';
export const history = createBrowserHistory();

const ga4react = new GA4React('G-9K53J71P74');

const trackGA = (loc: any) => {
    if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
        if (loc.pathname.indexOf('/en/console') === -1) {
            if (GA4React.isInitialized()) {
                ga4react.pageview(loc.pathname + loc.search);
            }
        }
    }
};

if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
    const initGA = async () => {
        await ga4react.initialize();
        trackGA(window.location);
    };
    initGA();
}

history.listen(location => {
    OnHistoryChange(location);
});

export function OnHistoryChange(location: Location, init?: boolean) {
    trackGA(location);
}

export const OpenPage = (path: string) => (e?: React.MouseEvent) => {
    e?.preventDefault();
    history.push(generatePath(`/:lang${path ? '/' + path : ''}`, {lang: i18next.language}));
    window.scrollTo(0, 0);
};

export const GetPath = (path: string) => {
    return generatePath(`/:lang${path ? '/' + path : ''}`, {lang: i18next.language});
};

export const OpenLink = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    let path = e.currentTarget.attributes.getNamedItem('href')?.value;
    if (path) {
        history.push(`${path}`);
    }
    window.scrollTo(0, 0);
};
