import {CreateError, ResultWithError} from 'packages/errors/errors';
import {Runtype} from 'runtypes';

export default function CheckResultRuntime<A = unknown, E = unknown>(
    runtype: Runtype<A>,
    data: ResultWithError<A, E>
): ResultWithError<A, E> {
    if (data[0] !== null) {
        const res = runtype.validate(data[0]);
        if (!res.success) {
            console.error(`Validation error`, res.details);
            return [null, CreateError('runtime validation error')];
        }
    }

    return data;
}
