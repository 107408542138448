import {CreateSheet, css} from 'aphrodite';
import Header from '../header/header.react';
import Footer from '../footer/footer.react';
import {MakeButton, SecondaryFilled} from 'src/themes/button';
import {TextColor, Secondary} from 'src/themes/colors';
import {ReactComponent as ArrowIcon} from 'src/icons/arrow-back.svg';
import {ReactComponent as Image404} from './404image.svg';
import {Sizes} from 'src/themes/layouts';
import {OpenPage} from 'packages/history/history';

export default function Page404() {
    const onBackClick = (e: React.MouseEvent) => {
        OpenPage('')(e);
    };
    return (
        <article className={css(Styles.page)}>
            <Header />
            <div className={css(Styles.main)}>
                <div className={css(Styles.container)}>
                    <div className={css(Styles.textContainer)}>
                        <div className={css(Styles.textHeading)}>
                            Ooops!
                            <br />
                            Page Not Found
                        </div>
                        <div className={css(Styles.text)}>
                            Sorry, we can't find the page you were looking for! We suggest you back to home page
                        </div>
                        <button
                            onClick={onBackClick}
                            className={css(MakeButton(SecondaryFilled), Styles.backButton)}
                        >
                            <ArrowIcon className={css(Styles.arrowIcon)} />
                            Back to Homepage
                        </button>
                    </div>
                    <div className={css(Styles.imageContainer)}>
                        <Image404 className={css(Styles.image404)} />
                    </div>
                </div>
            </div>
            <Footer />
        </article>
    );
}

const Styles = CreateSheet({
    page: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        marginTop: Sizes.header,
        flexGrow: 1,
        minHeight: `calc(100vh - ${Sizes.header}px)`,
        display: 'flex',
        justifyContent: 'center',
        '@media(max-width: 650px)': {
            minHeight: 0,
        },
    },
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        rowGap: '40px',
        columnGap: '80px',
        maxWidth: 1200,
        '@media(max-width: 1200px)': {
            flexDirection: 'column',
            padding: '40px 45px',
        },
        '@media(max-width: 650px)': {
            flexDirection: 'column',
            padding: '40px 20px',
        },
    },
    textContainer: {
        '@media(max-width: 1200px)': {
            width: '100%',
        },
    },
    textHeading: {
        color: TextColor,
        fontSize: 40,
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 800,
        lineHeight: 1.2,
        marginBottom: 20,
        '@media(max-width: 650px)': {
            fontSize: 30,
        },
    },
    text: {
        color: Secondary,
        fontSize: 20,
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 1.5,
        marginBottom: 40,
    },
    backButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        '@media(max-width: 650px)': {
            width: '100%',
        },
    },
    arrowIcon: {
        display: 'block',
        width: 14,
        height: 14,
    },
    imageContainer: {},
    image404: {
        width: '100%',
        height: 'auto',
        minWidth: 660,
        '@media(max-width: 750px)': {
            minWidth: 0,
        },
    },
});
