import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { ResultWithError } from "packages/errors/errors";
import { post } from "packages/rest/api";
import { SessionRaw, SessionRuntime } from "../model";

export type CreateUserRequest = {
    email?:    string
    phone?:    string
    password:  string
    full_name: string
}

export async function CreateUserAPI({ email, phone, password, ...rest }:CreateUserRequest):Promise<ResultWithError<SessionRaw>> {
    if (email === undefined && phone === undefined) {
        throw new Error("Phone or Email required");   
    }
    
    const res = await post({url: "session", body: {
        new_email:    email,
        new_phone:    phone,
        new_password: password,
        ...rest,
    }});
    
    return CheckResultRuntime(SessionRuntime, res);
}
