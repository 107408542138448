import * as runtypes from 'runtypes';

export const SessionUserRuntime = runtypes.Record({});

export const SessionExtraRuntime = runtypes.Record({});

export const SessionRuntime = runtypes.Record({
    user: SessionUserRuntime,
    extra: SessionExtraRuntime,
});

export type SessionUser = runtypes.Static<typeof SessionUserRuntime>;
export type SessionExtra = runtypes.Static<typeof SessionExtraRuntime>;
export type SessionRaw = runtypes.Static<typeof SessionRuntime>;
export type Session = runtypes.Static<typeof SessionRuntime> & {
    Reload: () => Promise<void>;
    UpdateUserFields: (data: Partial<SessionUser>) => void;
};
