import { ToastLayout, ToastTheme, options, ToastContent, ShowToast } from 'packages/toasts/ShowToast';
import { TextColor } from './colors';

const DefaultLayout:ToastLayout = {
    padding:        16,
    borderRadius:   4,
    boxShadow:      "0 2px 20px 0 rgb(34 41 47 / 8%)",
    fontSize:       15,
}

const DefaultToastTheme:ToastTheme = {
    backgroundColor:    "#EFEFEF",
    prgoressColor:      TextColor,
    crossColor:         TextColor,
    color:              TextColor,
}

export function ShowDefaultToast(Content:ToastContent, options?: options) {
    return ShowToast(Content, DefaultToastTheme, DefaultLayout, options)
}