export const Accent1 = '#34A52E';
export const LightAccent1 = '#9EE8A0';

export const Accent2 = '#F75D4A';
export const LightAccent2 = '#FCBFB3';

export const GreenBg = '#E8F8EA';
export const PageBg = '#FFFFFF';
export const ElementBg = '#FFFFFF';
export const PageGrayBg = '#fafafa';
export const RedBg = '#FFECE9';

export const StrokeColor = '#E6E6E6';

export const TextColor = '#161616';
export const WhiteText = '#FFFFFF';
export const TextArea = '#f5f5f5';
export const Primary = '#595959';
export const Secondary = '#868686';
export const LightGray = '#DFDFDF';
export const Gray = '#B2B2B2';
export const DarkGray = '#303030';

