import {GetPath, history} from 'packages/history/history';
// icons
import {ReactComponent as LogoImg} from 'src/icons/logo-full.svg';
import {ReactComponent as LogoWhite} from 'src/icons/logo-white.svg';
import {ReactComponent as ShortLogo} from 'src/icons/logo.svg';
// styles
import {css, CreateSheet, StyleDeclaration} from 'packages/aphrodite/aphrodite';

interface ExtraStyles {
    logo?: StyleDeclaration;
}

interface Props {
    short?: boolean;
    white?: boolean;
    styles?: ExtraStyles;
}

export default function Logo({short, white, styles}: Props) {
    const OpenPage = (url: string) => (e?: React.MouseEvent) => {
        e && e.preventDefault();
        e && e.stopPropagation();

        history.push(`${url}`);
    };

    return (
        <a
            {...{
                className: css(Styles.logo),
                href: GetPath(''),
                onClick: OpenPage(GetPath('')),
                'aria-label': 'Motoragazzi',
            }}
        >
            {white ? (
                <LogoWhite className={css(Styles.icon, styles && styles.logo && styles.logo)} />
            ) : short ? (
                <ShortLogo className={css(Styles.icon, styles && styles.logo && styles.logo)} />
            ) : (
                <LogoImg className={css(Styles.icon, styles && styles.logo && styles.logo)} />
            )}
        </a>
    );
}

const Styles = CreateSheet({
    logo: {
        cursor: 'pointer',
        userSelect: 'none',
    },
    icon: {
        display: 'block',
        width: 'auto',
        height: 40,
    },
});
