import {LightboxTheme} from 'packages/lightbox/lightbox';
import { TextColor, WhiteText} from './colors';

export const LightLightbox: LightboxTheme = {
    overlay: {
        backgroundColor: 'rgba(48, 48, 48, 0.5)',
        closeIconColor: WhiteText,
        hoverIconColor: 'rgba(196, 196, 196, 0.2)',
    },
    lightbox: {
        backgroundColor: WhiteText,
        color: TextColor,
        boxShadow: '0px 10px 20px rgba(48, 48, 48, 0.15)',
        closeIconColor: WhiteText,
        hoverIconColor: 'rgba(196, 196, 196, 0.2)',
    },
    header: {
        background: TextColor,
        color: WhiteText,
    },
    body: {
        backgroundColor: undefined,
        color: undefined,
    },
    footer: {
        backgroundColor: undefined,
        color: undefined,
        botderTop: undefined,
    },
};

export const DarkLightbox: LightboxTheme = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        closeIconColor: WhiteText,
        hoverIconColor: 'rgba(196, 196, 196, 0.2)',
    },
    lightbox: {
        backgroundColor: '#283046',
        color: '#d0d2d6',
        boxShadow: '0 5px 20px 0 rgba(34,41,47,.1)',
        closeIconColor: WhiteText,
        hoverIconColor: 'rgba(196, 196, 196, 0.2)',
    },
    header: {
        background: '#161d31',
        color: undefined,
    },
    body: {
        backgroundColor: undefined,
        color: WhiteText,
    },
    footer: {
        backgroundColor: undefined,
        color: undefined,
        botderTop: '1px solid #3b4253',
    },
};

export const WarningLight: LightboxTheme = {
    ...LightLightbox,
    header: {
        ...LightLightbox.header,
    },
};
