import EventEmitter from 'eventemitter3';
import {ErrorData} from 'packages/errors/errors';
import {ShowDefaultToast} from 'src/themes/toasts';
import {CreateUserAPI, CreateUserRequest} from './API/CreateUserAPI';
import {LoadSessionAPI} from './API/LoadSessionAPI';
import {Session, SessionRaw, SessionUser} from './model';
import {RequestResetPasswordAPI, RequestResetPasswordRequest} from './API/RequestResetPasswordAPI';
import {ResendEmailVerificationAPI} from './API/ResendEmailVerificationAPI';
import {SetNewPasswordFromResetAPI, SetNewPasswordFromResetRequest} from './API/SetNewPasswordFromResetAPI';
import {SignInAPI, SignInRequest} from './API/SignInAPI';
import {SignOutAPI} from './API/SignOutAPI';
import {VerifyRequestedPhoneAPI} from './API/VerifyRequestedPhoneAPI';
import {VerifyRequestedEmailAPI} from './API/VerifyRequestedEmailAPI';

export const Events = new EventEmitter<{
    'sign-in': () => void;
    'sign-out': () => void;
    'session-changed': () => void;
}>();

const SessionEmpty: Session = {
    user: {
        guid: '',
        is_verified: false,
        primary_email: null,
        requested_email: null,
        primary_phone: null,
        requested_phone: null,
        full_name: '',
        has_password: false,
    },
    extra: {
        active_tour_operator_id: 0,
        tour_operators: [],
        superuser_flags: {},
        is_superuser: false,
        su_login: {},
    },
    Reload: async function (this: Session) {
        await LoadSession();
        Events.emit('session-changed');
    },
    UpdateUserFields: function (this: Session, data: Partial<SessionUser>) {
        this.user = {...this.user, ...data};
        Events.emit('session-changed');
    },
};

let session: Session = {...SessionEmpty};

function SetSession(newSession: SessionRaw) {
    session = {...session, ...newSession};
}

// usually such functions should not be located here
// but session is a bit different case
export async function LoadSession(): Promise<Session> {
    const res = await LoadSessionAPI();
    if (res[1] !== null) {
        console.error('session error: ', res[1].text);
    } else {
        SetSession(res[0]);
    }

    return session;
}

export async function SignUp(rq: CreateUserRequest): Promise<ErrorData | null> {
    const res = await CreateUserAPI(rq);
    if (res[1] === null) {
        SetSession(res[0]);
    } else {
        return res[1];
    }

    if (res[0].user.is_verified) {
        ShowDefaultToast('Successfully signed up');
    }

    Events.emit('session-changed');
    return null;
}

export async function VerifyRequestedEmailOrPhone(
    code: string,
    is_phone: boolean,
    identifier?: string
): Promise<ErrorData | null> {
    const res = !is_phone ? await VerifyRequestedEmailAPI(code) : await VerifyRequestedPhoneAPI(code, identifier);
    if (res[1] === null) {
        SetSession(res[0]);
    } else {
        return res[1];
    }

    if (res[0].user.is_verified) {
        ShowDefaultToast('Successfully signed in');
    }

    Events.emit('sign-in');
    return null;
}

export async function SignIn(rq: SignInRequest): Promise<ErrorData | null> {
    const res = await SignInAPI(rq);
    if (res[1] === null) {
        SetSession(res[0]);
    } else {
        return res[1];
    }

    if (res[0].user.is_verified) {
        ShowDefaultToast('Successfully signed in');
    }

    Events.emit('sign-in');
    return null;
}

export async function HiddenSignIn(): Promise<ErrorData | null> {
    await LoadSession();

    if (session.user.is_verified) {
        ShowDefaultToast('Successfully signed in');
    }

    Events.emit('sign-in');
    return null;
}

export async function SignOut() {
    const res = await SignOutAPI();
    if (res[1] !== null) {
        console.error('session error: ', res[1].text);
    } else {
        window.location.href = '/';
        SetSession(res[0]);
    }
    ShowDefaultToast('Successfully signed out');
    Events.emit('sign-out');
}

export async function RequestResetPassword(rq: RequestResetPasswordRequest) {
    return await RequestResetPasswordAPI(rq);
}

export async function SetNewPasswordFromReset(rq: SetNewPasswordFromResetRequest) {
    return await SetNewPasswordFromResetAPI(rq);
}

export async function ResendEmailVerification(): Promise<ErrorData | null> {
    return await ResendEmailVerificationAPI();
}

export function listenOnSignIn(func: () => void) {
    Events.addListener('sign-in', func);
}

export function unlistenOnSignIn(func: () => void) {
    Events.removeListener('sign-in', func);
}

export function listenOnSignOut(func: () => void) {
    Events.addListener('sign-out', func);
}

export function unlistenOnSignOut(func: () => void) {
    Events.removeListener('sign-out', func);
}

export function listenOnSessionChanges(func: () => void) {
    Events.addListener('session-changed', func);
}

export function unlistenOnSessionChanges(func: () => void) {
    Events.removeListener('session-changed', func);
}

export {session};
