import i18next, {Resource} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import {handleDetectedLanguage} from './localization';

const resources: Resource = {}; // will load resources later dynamically
i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            resources, // object which contains translations
            debug: false, // helps in finding issues while loading
            load: 'languageOnly', // looks up only for 'en' language codes format
            fallbackLng: 'en', // fallbacks to the EN when no resource found for any other language
            detection: {
                lookupFromPathIndex: 0,
                order: ['path'],
            },
            returnEmptyString: false,
        },
        handleDetectedLanguage
    );
